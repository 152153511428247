.forms-input {
  display: block;
  position: relative;
  margin-bottom: 1.25rem;
  overflow: hidden;

  input,
  textarea {
    background-color: white;
    box-shadow: inset 0 1px 2px rgba(27, 31, 35, 0.075);

    @include border(1px, $border-light-gray);
    @include border-radius(3px);
    @include default-transition();
  }

  label {
    padding: 0.5rem 0;
    width: 100%;
    font-size: 1.4rem;
    font-weight: 500;
    display: inline-block;
    vertical-align: middle;
    color: $text-dark-gray;
    text-align: left;
  }
}
