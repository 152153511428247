.btn-primary {
  display: flex;
  align-items: center;
  background-color: $color-primary;
  border-color: $color-primary;

  &:active,
  &:focus,
  &:hover {
    background-color: $color-primary-light;
    border-color: $color-primary-light;
  }
}

// @
// Table button
// Classes * default, blue, red, green
.table-button {
  @include remove-webkit();
  @include border-radius(3px);
  @include default-transition();

  margin-bottom: 1rem;
  padding: 0.7rem 1rem;
  font-size: 1.2rem;
  font-weight: 500;
  text-decoration: none;
  border-width: 1px;
  border-style: solid;
  cursor: pointer;
  border-color: $border-default;

  &.default {
    color: $text-dark-gray;
    &:hover {
      background-color: $text-dark-gray;
      border-color: $text-dark-gray;
      color: white;
    }
  }
  &.blue {
    color: $text-blue;
    &:hover {
      background-color: $color-primary;
      border-color: $color-primary;
      color: white;
    }
  }
  &.red {
    color: $text-red;
    &:hover {
      background-color: $border-red;
      border-color: $border-red;
      color: white;
    }
  }
  &.green {
    color: $text-green;
    &:hover {
      background-color: $border-green;
      border-color: $border-green;
      color: white;
    }
  }
  &:last-child {
    margin-left: 0.5rem;
  }
}

// @
// Header button
// Classes * default, blue, red, green
.header-button {
  @include remove-webkit();
  @include border-radius(5px);
  @include default-transition();
  margin-top: 0;
  padding: 1.2rem 1.5rem;
  font-size: 1rem;
  font-size: 1.4rem;
  font-weight: 500;
  text-decoration: none;
  border-width: 1px;
  border-style: solid;
  border-color: $border-default;
  cursor: pointer;

  @include respond(tab-port) {
    margin-top: 2rem;
  }

  &.default {
    color: white;
    background-color: $text-dark-gray;
    border-color: $text-dark-gray;

    &:hover {
      color: white;
      background-color: transparent;
    }
  }

  &.blue {
    color: white;
    background-color: $color-primary;
    border-color: $color-primary;
    &:hover {
      color: $color-primary;
      background-color: transparent;
    }
  }
  &.green {
    background-color: $text-green;
  }
  &.red {
    color: white;
    background-color: $border-red;
    border-color: $border-red;
    &:hover {
      color: $border-red;
      background-color: transparent;
    }
  }
  &.green {
    color: white;
    background-color: $border-green;
    border-color: $border-green;
    &:hover {
      color: $border-green;
      background-color: transparent;
    }
  }
  &:last-child {
    margin-left: 0.5rem;
  }
  &:disabled {
    opacity: 0.3;
  }
}

// @
// Regular button
// Classes * default, blue, red, green
.regular-button {
  @include remove-webkit();
  @include border-radius(5px);
  @include default-transition();
  padding: 1.2rem 1.5rem;
  font-size: 1rem;
  font-size: 1.4rem;
  font-weight: 500;
  text-decoration: none;
  border-width: 1px;
  border-style: solid;
  border-color: $border-default;

  cursor: pointer;
  &.default {
    color: white;
    background-color: $text-dark-gray;
    border-color: $text-dark-gray;
    &:hover {
      color: white;
      background-color: transparent;
    }
  }
  &.blue {
    color: white;
    background-color: $color-primary;
    border-color: $color-primary;
    &:hover {
      color: $color-primary;
      background-color: transparent;
    }
  }
  &.red {
    color: white;
    background-color: $border-red;
    border-color: $border-red;
    &:hover {
      color: $border-red;
      background-color: transparent;
    }
  }
  &.green {
    color: white;
    background-color: $border-green;
    border-color: $border-green;
    &:hover {
      color: $border-green;
      background-color: transparent;
    }
  }
  &:last-child {
    margin-left: 1rem;
  }
  &:disabled {
    opacity: 0.3;
  }
}

// @
// Default style button
// Center icon inside button
button {
  span {
    svg {
      vertical-align: baseline;
    }
  }
}
