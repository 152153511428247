@import '../../styles//modules/load.scss';

.main-pane {
  width: 100%;
}

.pane {
  position: fixed;
  overflow-y: scroll;
  top: 0;
  right: 0;
  padding-bottom: 7rem;
  min-width: 38%;
  max-width: 60rem;
  width: 50%;
  min-height: 100vh;
  height: 100%;
  background-color: white;
  border-left: 1px solid $color-grey-light-3;
  box-shadow: fade-out($color: #000000, $amount: 0) 0 7px 9px -7px;
  transition: all 0.1s ease;
  z-index: $high-priority;

  @include respond(tab-port) {
    width: 70%;
  }
}

.parent-pane {
  display: flex;

  @include respond(big-desktop) {
    display: block;
  }
}

.hidden-pane {
  opacity: 0;
  visibility: hidden;
}
