@import '../../styles/modules/load';

.loading-component {
  width: 100%;
  margin-top: 2rem;
  display: block;
  text-align: center;
  span {
    font-size: 2.5rem;
  }
  h1 {
    color: $text-dark;
    font-size: 1.5rem;
    margin: 0;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    font-weight: 300;
  }
  button {
    @include remove-webkit();
    @include default-transition();
    background-color: $base-blue;
    color: white;
    font-size: 0.9rem;
    font-weight: 500;
    padding: 0.5rem 0.75rem;
    border-radius: 3px;
    border: 1px solid $border-dark-blue;
    border-bottom-width: 2px;
    cursor: pointer;
    &:hover {
      background-color: $base-dark-blue;
    }
    &:active {
      transform: scale(0.95);
    }
  }
}
