// Transform
// *
@mixin transform($amount: -50%) {
  -webkit-transform: translate($amount, $amount);
  -ms-transform: translate($amount, $amount);
  transform: translate($amount, $amount);
}

// Border
// *
@mixin border($amount, $color, $direction: all) {
  @if $direction == all {
    border: $amount solid $color;
  } @else {
    border-#{$direction}: $amount solid $color;
  }
}

// Border radius
// *
@mixin border-radius($value, $direction: all) {
  @if $direction == all {
    -webkit-border-radius: $value;
    -moz-border-radius: $value;
    border-radius: $value;
  } @else {
    border-#{$direction}-left-radius: $value;
    border-#{$direction}-right-radius: $value;
  }
}

// Box shadow
// *
@mixin box-shadow($value) {
  -moz-box-shadow: $value;
  -webkit-box-shadow: $value;
  box-shadow: $value;
}

// Default transition
// *
@mixin default-transition($element: all) {
  -webkit-transition: $element ease 0.1s;
  -moz-transition: $element ease 0.1s;
  -o-transition: $element ease 0.1s;
  transition: $element ease 0.1s;
}

// Remove webkit
// *
@mixin remove-webkit() {
  outline: 0;
  border: 0;
  -webkit-appearance: none;
}

// Position
// *
@mixin position(
  $position,
  $top: null,
  $right: null,
  $bottom: null,
  $left: null
) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

// No padding or margin
// *
@mixin no-padding-margin() {
  padding: 0;
  margin: 0;
}

// Placeholder
// *
@mixin input-placeholder {
  &.placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
}

// Size
// *
@mixin size($width, $height) {
  width: $width;
  height: $height;
}

// Inline block
// *
@mixin inline-block($direction: top, $width: null) {
  width: $width;
  display: inline-block;
  vertical-align: $direction;
}

// Font setup
// *
@mixin font-style($margin: null, $weight: null, $size: null) {
  margin: $margin;
  font-weight: $weight;
  font-size: $size;
}

// Button gradient
// *
@mixin button-gradient($firstColor, $secondColor) {
  background-image: -webkit-gradient(
    linear,
    0 0,
    0 100%,
    from(rgba($border-green, 0.3)),
    to(rgba($border-green, 0.5))
  );
  background-image: -moz-linear-gradient(
    rgba($border-green, 0.3),
    rgba($border-green, 0.5)
  );
  background-image: -ms-linear-gradient(
    rgba($border-green, 0.3),
    rgba($border-green, 0.5)
  );
  background-image: -o-linear-gradient(
    rgba($border-green, 0.3),
    rgba($border-green, 0.5)
  );
  background-image: linear-gradient(
    rgba($border-green, 0.3),
    rgba($border-green, 0.5)
  );
}

// Replace text with three dots if too long
// *
@mixin text-overflow() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// MEDIA QUERIES
/*
0 - 600px: phone
600 - 900px: Tablet portrait
900 - 1200px: Tablet landscape
[1200 - 1800] is where oue normal styles apply
1800px +: Big desktop
*/

@mixin respond-phone {
  @media (max-width: 600px) {
    @content;
  }
}

/*
$breakpoint argument choices:
- phone
- tab-port
- tab-land
- big-desktop
*/
@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media only screen and (max-width: 37.5em) {
      // 600px
      @content;
    }
  }

  @if $breakpoint == tab-port {
    @media only screen and (max-width: 56.25em) {
      // 900px
      @content;
    }
  }
  @if $breakpoint == tab-land {
    @media only screen and (max-width: 75em) {
      // 1200px
      @content;
    }
  }
  @if $breakpoint == big-desktop {
    @media only screen and (max-width: 112.5em) {
      // 1800px
      @content;
    }
  }
}
