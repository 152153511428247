@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-10rem);
  }

  80% {
    transform: translateX(1rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(10rem);
  }

  80% {
    transform: translateX(-1rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(3rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.releases-spinner {
  border: 2px solid hsla(0, 0%, 100%, 0.5);
  border-radius: 50%;
  border-top: 2px solid #fff;
  width: 2em;
  height: 2em;
  -webkit-animation: spin 1s ease infinite;
  animation: spin 1s ease infinite;
  &.big {
    -webkit-animation: spin 1s ease infinite;
    animation: spin 1s ease infinite;
    border: 3px solid rgba(225, 228, 232, 0.15);
    border-top: 3px solid #6495ed;
    width: 4em;
    height: 4em;
  }
  &.in-button {
    border: 1px solid #fff;
    border-top: 1px solid #4982e8;
    width: 1.2em;
    height: 1.2em;
    -webkit-animation: spin 0.5s linear infinite;
    animation: spin 0.5s linear infinite;
    position: absolute;
    top: 1.2em;
    right: 1.2em;
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(1turn);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
