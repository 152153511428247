html {
  font-size: 62.5%;
  @include no-padding-margin();
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  margin: 0;
  background-color: $color-grey;
  // box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  // background-color: #edf0fb;
}

#root {
  max-width: 192rem;
  margin: 0 auto;
}

.sheet-view {
  max-width: 100% !important;
  margin: inherit !important;

  .burger {
    display: none;
  }
}

.react-list-select {
  outline: none;
}

.dark-overlay {
  width: 100%;
  height: 100vh;
  opacity: 0.5;
  background-color: black;
  position: fixed;
  z-index: $mid-priority;
  overflow: hidden;
  top: 0;
}

.sider-dark-overlay {
  margin-top: -7rem;
  width: 100%;
  height: 100vh;
  opacity: 0.5;
  background-color: black;
  position: fixed;
  z-index: 1;
  overflow: hidden;
  z-index: $high-priority;
}
