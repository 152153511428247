#page-not-found {
  width: 100%;
  display: table;
  text-align: center;
  left: 0;
  #page-not-found-inner {
    display: table-cell;
    vertical-align: middle;
    #page-not-found-content {
      width: 500px;
      display: block;
      margin: 0 auto;
      margin-top: 4rem;
      img {
        max-width: 12.5rem;
        border-radius: 2px;
        border: 0.25rem solid #dddddd;
      }
      h1 {
        color: #33393d;
        font-size: 1.5rem;
        font-weight: 300;
      }
      a {
        color: #1073c2;
        font-size: 1rem;
        text-decoration: none;
        letter-spacing: -0.025rem;
      }
    }
  }
}
