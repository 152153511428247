@import '../../styles/modules/load.scss';

/* <li> */
.nav-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-exit {
  padding: 0;
  height: 0;
}

/* Icon Button */
.icon-button {
  border-radius: 50%;
  padding: 0.5rem;
  margin: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: filter 300ms;
}

.icon-button:hover {
  filter: brightness(1.2);
}

.icon-button svg {
  fill: $select-text-color;
  width: 20px;
  height: 20px;
}

/* Dropdown Menu */

.dropdown {
  position: absolute;
  top: 5.8rem;
  width: 25rem;
  min-height: 12rem;
  transform: translateX(-45%);
  background-color: $color-grey-dark-1;
  border: $select-border;
  border-radius: $select-border-radius;
  padding: 1rem;
  overflow: hidden;
  transition: height $select-speed ease;
  z-index: $high-priority;
}

.menu-item {
  height: 5rem;
  display: flex;
  align-items: center;
  border-radius: $select-border-radius;
  transition: background $select-speed;
  color: $color-grey-light-1;
  padding: 0.5rem;

  &:hover {
    color: $color-grey-light-4;
  }
}

.menu-item .icon-button {
  margin-right: 2rem;
}

.menu-item .icon-button:hover {
  filter: none;
}

.menu-item:hover {
  background-color: #525357;
}

.icon-right {
  margin-left: auto;
}

/* CSSTransition classes  */
.menu-primary-enter {
  position: absolute;
  transform: translateX(-110%);
}
.menu-primary-enter-active {
  transform: translateX(0%);
  transition: all $select-speed ease;
}
.menu-primary-exit {
  position: absolute;
}
.menu-primary-exit-active {
  transform: translateX(-110%);
  transition: all $select-speed ease;
}

.menu-secondary-enter {
  transform: translateX(110%);
}
.menu-secondary-enter-active {
  transform: translateX(0%);
  transition: all $select-speed ease;
}
.menu-secondary-exit {
}
.menu-secondary-exit-active {
  transform: translateX(110%);
  transition: all $select-speed ease;
}
