.main-content {
  display: flex;
}

.main-view {
  flex: 1;
  max-width: 82%;
  margin: auto;
  margin-top: 0;

  @include respond(tab-land) {
    max-width: 100%;
  }
}
