@font-face {
  font-family: 'Neue Haas Unica Pro';
  src: url('./fonts/NeueHaasUnicaPro-Medium.woff2') format('woff2'),
    url('./fonts/NeueHaasUnicaPro-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Neue Haas Unica Pro';
  src: url('./fonts/NeueHaasUnicaPro-Light.woff2') format('woff2'),
    url('./fonts/NeueHaasUnicaPro-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Neue Haas Unica Pro';
  src: url('./fonts/NeueHaasUnicaPro-Bold.woff2') format('woff2'),
    url('./fonts/NeueHaasUnicaPro-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Neue Haas Unica Pro';
  src: url('./fonts/NeueHaasUnicaPro-Regular.woff2') format('woff2'),
    url('./fonts/NeueHaasUnicaPro-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Neue Haas Unica Pro';
  src: url('./fonts/NeueHaasUnicaPro-Thin.woff2') format('woff2'),
    url('./fonts/NeueHaasUnicaPro-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}
