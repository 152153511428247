.sidebar {
  position: relative;
  flex: 0 0 18%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  max-width: 28rem;
  margin-top: -7rem;
  background-color: $color-grey-dark-1;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  transition: transform 0.2s;

  @include respond(tab-land) {
    position: absolute;
    left: 0;
    flex: 0 0 10%;
    z-index: $high-priority + 1;
    overflow-y: scroll;
    overflow-x: hidden;
    max-width: 30rem;
    height: 100%;
    transform: translateX(-100%);
  }

  @include respond(phone) {
    max-width: 27rem;
  }

  .logo {
    display: flex;
    align-items: center;
    margin: auto;
    margin-bottom: 3rem;
    width: 18rem;

    &__image {
      width: 100%;
      display: block;
    }
  }

  .main-title {
    margin: 0;
    margin-left: 1rem;
    font-size: 3.2rem;
    font-family: Arial, Helvetica, sans-serif;
    color: white;
  }

  .main-action {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.5rem;
    padding: 2rem;
    width: 15rem;
    font-size: 1.6rem;
    text-align: center;
  }

  .nav-item {
    cursor: pointer;
  }

  .dropdown {
    top: 15rem;
    transform: translateX(45%);
    z-index: 15;

    @include respond(tab-land) {
      transform: translateX(0);
    }
  }

  .side-nav {
    margin-top: 2rem;
    padding-left: 0;
    font-size: 1.4rem;
    list-style: none;

    &__item {
      display: flex;
      justify-content: space-between;
      position: relative;

      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }
    }

    &__item::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 3px;
      background-color: $color-primary;
      transform: scaleY(0);
      // transform-origin: bottom;
      transition: transform 0.2s, width 0.4s cubic-bezier(1, 0, 0, 1) 0.2s,
        background-color 0.1s;
    }

    &__item:hover::before,
    &__item--active::before {
      transform: scaleY(1);
      width: 100%;
    }

    &__item:active::before {
      background-color: $color-primary-light;
    }

    &__total,
    &__link:link,
    &__link:visited {
      position: relative;
      z-index: 10;
      padding: 1.5rem 3rem;
      color: $color-grey-light-1;
      text-decoration: none;
      text-transform: uppercase;
      display: flex;
      align-items: center;
    }

    &__link {
      flex: 0 0 65%;
    }

    &__icon {
      width: 1.75rem;
      height: 1.75rem;
      margin-right: 2rem;
      fill: currentColor; // color of the current element or parent
    }

    &__divider {
      margin: auto;
      padding: 1rem;
      margin-bottom: 2rem;
      width: 80%;
      height: 0.1rem;
      border-bottom-width: thin;
      border-bottom: 1px solid $border-default;
    }
  }

  ///////////////////////////////////////
  // USER NAVIGATION

  .legal {
    padding: 2.5rem;
    font-size: 1.2rem;
    color: $color-grey-light-4;
    text-align: center;
  }
}

.sidebar-active {
  transform: translateX(0);
}
