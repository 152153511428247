@import '../../../styles/modules/load';

#table-navigator {
  width: 100%;
  padding: 0.75rem 1rem;
  text-align: center;
  border-top: 1px solid #e2e2e2;
  #col {
    display: inline-block;
    vertical-align: middle;
    width: 50%;
    &:first-child {
      text-align: left;
    }
    &:last-child {
      text-align: right;
    }
    &:first-child p {
      background-color: rgba(64, 68, 72, 0.1);
      display: inline-block;
      color: #414549;
      padding: 0.5rem;
      border-radius: 2px;
      font-size: 0.9rem;
    }
    &:last-child button {
      @include remove-webkit();
      @include default-transition();
      background-color: rgba(64, 68, 72, 0.1);
      color: #414549;
      font-size: 1.65rem;
      display: inline-block;
      border-radius: 2px;
      cursor: pointer;
      &:last-child {
        margin-left: 1px;
      }
      &:disabled {
        color: #dedede;
      }
      &:active {
        transform: scale(0.9);
        color: $text-blue;
        background-color: rgba(16, 115, 194, 0.1);
      }
    }
  }
}

#key-listener {
  position: absolute;
  background-color: red;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
}
