@import '../../styles/modules/load';

$broken-heart: '../../images/SVG/broken-heart.svg';

.emoji-container {
  width: 100%;
  display: block;

  .emoji {
    width: 2.5rem;
    height: 2.5rem;
    display: block;
    margin: 0 auto;
    background-size: cover;
    background-position: center center;
    &.heartbreak {
      background-image: url($broken-heart);
      background-repeat: no-repeat;
    }
  }
}
