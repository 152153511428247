.infringement-modal {
  .ant-form-item-label {
    text-align: left;
  }
}

.vendors-modal {
  .ant-space-item {
    width: 100%;
  }
}

.templates-modal {
  width: 90% !important;
  max-width: 768px;

  .templates-list {
    margin-top: 40px;
  }

  .list-item-link {
    display: flex;
    align-items: center;
    color: #108ee9;

    span {
      padding-right: 5px;
    }
  }

  .ant-card-bordered {
    max-width: 250px;
    background: rgba(60, 90, 100, 0.04);

    .ant-card-head-title {
      display: flex;
      align-items: center;

      span {
        cursor: pointer;
        padding-right: 5px;
      }
    }

    .ant-card-body {
      align-items: center;
      display: flex;

      span {
        padding-right: 5px;
      }
    }
  }

  .ant-col {
    .ant-list-item {
      margin-bottom: 0;
      padding-top: 16px;
      padding-bottom: 16px;

      &:hover {
        cursor: pointer;
        background: rgba(60, 90, 100, 0.04);
      }
    }
  }
}
