@import '../../../styles/modules/load.scss';

.array-input {
  display: block;
  .array-input-items {
    display: block;
    button {
      @include remove-webkit();
      font-size: 1.2rem;
      font-weight: 500;
      color: $text-blue;
      margin-right: 1rem;
      margin-bottom: 1rem;
      padding: 2rem 1.5rem;
      border-radius: 2px;
      text-transform: capitalize;

      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        text-decoration: line-through;
        color: $text-red;
        background-color: rgba($text-red, 0.1);
      }
    }
  }
  .array-input-list {
    display: block;
    border: 1px solid $border-light-gray;
    padding: 1.5rem;
    padding-bottom: 1.5rem;
    h1 {
      color: $text-dark;
      font-size: 1.2rem;
      font-weight: 500;
      margin-top: 0;
      margin-bottom: 1rem;
      padding-bottom: 1rem;
      border-bottom: 1px solid $border-light-gray;
    }
    button {
      @include remove-webkit();
      font-size: 1.2rem;
      font-weight: 500;
      background-color: rgba(16, 115, 194, 0.1);
      color: $text-blue;
      margin-right: 1rem;
      margin-bottom: 1rem;
      padding: 0.35rem 1rem;
      border-radius: 2px;
      cursor: pointer;
      text-transform: capitalize;

      &:disabled {
        opacity: 0.25;
      }
    }
  }
}
