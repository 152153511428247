.context-menu {
  position: relative;
  top: -1rem;

  li {
    @include remove-webkit();
    @include default-transition();
    padding: 0;
    text-decoration: none;
    display: block;
    width: 100%;
    font-size: 1.2rem;
    font-weight: 500;
    text-decoration: none;
    color: $text-dark;
    margin: 0;
    text-align: left;
    cursor: pointer;

    a,
    span,
    div {
      display: inline-block;
      margin: 0;
      padding: 1rem 1.5rem;
      color: $text-dark;
      font-weight: 500;
    }

    &:last-child {
      border-bottom: 0;
    }
    &:hover {
      color: $text-blue;
    }
  }

  .ant-dropdown-menu-submenu {
    .ant-dropdown-menu {
      padding: 1rem;
    }
  }
}

.create-menu {
  position: relative;
  top: 0;
  width: 100%;

  .ant-dropdown-menu-submenu-title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .ant-dropdown-menu-submenu-arrow {
      padding-left: 0;
      padding-right: 0;

      .anticon {
        font-size: 1.6rem;
      }
    }
  }
}

.project-menu {
  .ant-dropdown-menu-item {
    display: flex;
    align-items: center;
  }

  .project-item {
    padding-left: 0;
  }
}
