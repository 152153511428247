.tracks-table {
  .tracks {
    padding-bottom: 40px;
    transition: all 1s ease;
  }
  .is-dragging {
    background-color: #c2dbff;
  }

  .hidden {
    display: none;
  }
}
