// Base colors
// *
$base-white: white;
$base-blue: #0078d7;
$base-dark-blue: #0f61a6;
$base-light-gray: #f6f8fa;
$base-dark: #33393d;
$base-darker: #2c3235;

// Borders
// *
$border-default: #e2e2e2;
$border-whitesmoke: whitesmoke;
$border-dark-blue: #0f61a6;
$border-light-gray: #dedede;
$border-red: #db6f93;
$border-green: #3fc380;
$border-orange: #f79d16;

// Shadows
// *
$shadow-light-blue: #e4eff8;
$shadow-dark: #23282b;
// Text
// *
$text-dark: #212b35;
$text-dark-2: #151515;
$text-dark-3: #040404;

$text-blue: #1073c2;
$text-red: #db6f93;
$text-green: #3fc380;
$text-dark-gray: #404448;
$text-orange: #f79d16;

$color-primary: #eb2f64;
$color-primary-light: #ff3366;
$color-primary-dark: #ba265d;

$color-grey: #eee;
$color-grey-light-1: #faf9f9;
$color-grey-light-2: #f4f2f2;
$color-grey-light-3: #f0eeee;
$color-grey-light-4: #cccccc;
$color-grey-light-5: #c1c1c1;

$color-grey-dark-1: #333333;
$color-grey-dark-2: #777777;
$color-grey-dark-3: #999999;
$color-grey-dark-4: #4c4c4c;

$color-blue-1: #6495ed;
$color-blue-5: #40e0d0;

$color-red-1: #ff4500;
$color-red-2: #cd5c5c;

$color-green-1: #7ebf57;
$color-green-2: #3cb371;
$color-green-3: #2ecc71;

$dark-shadow: 0 0 4px 0 rgba(111, 117, 135, 0.35);
$default-border-radius: 6px;
$default-section-padding: 4rem 2rem;

// SELECTS
$select-bg: #242526;
$select-bg-accent: #484a4d;
$select-text-color: #dadce1;
$select-nav-size: 6rem;
$select-border: 1px solid #474a4d;
$select-border-radius: 8px;
$select-speed: 500ms;

// POSITIONING
$low-priority: 1;
$mid-priority: 99;
$high-priority: 999;
$mega-priority: 9999;
