@import '../../styles/modules/load';

.content-header {
  width: 100%;
  background-color: white;
  border-bottom: 1px solid $border-default;
  -webkit-box-shadow: inset 0 7px 9px -7px $shadow-light-blue;
  box-shadow: $dark-shadow;

  .content-header-inner {
    padding: 1.5rem;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  .track-heading {
    display: flex;

    .track-titles {
      display: inline-block;
      padding-left: 2rem;
    }

    h4 {
      margin-bottom: 0;
      font-size: 2.4rem;
    }

    span {
      font-size: 1.8rem;
    }
  }

  button {
    height: 5rem;
  }

  #col {
    display: inline-block;
    vertical-align: middle;
    width: 50%;
    text-align: left;
    &:last-child {
      text-align: right;
    }
    h2 {
      font-size: 1.25rem;
      font-weight: 500;
      color: $text-dark-gray;
      margin: 0;
    }
  }
}
