@import '../../../../styles/modules/load.scss';

.menucollapse {
  background-color: transparent !important;

  .ant-collapse-item {
    border-bottom: none !important;
  }

  .ant-collapse-header {
    color: $color-grey-light-1 !important;
    padding-left: 30px !important;
  }

  svg {
    font-size: 15px;
    vertical-align: baseline;
  }

  .item-menu {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;

    label {
      cursor: pointer;
    }
  }

  .ant-collapse-content-box {
    padding-right: 0px !important;
    svg {
      min-height: 18px;
      min-width: 18px;
    }
  }

  .ant-collapse-active > .ant-collapse-header {
    background-color: #eb2f64;
  }

  .track-item-total {
    align-self: center;
    padding-right: 10px;
    color: white;
    z-index: 10;
  }
}
