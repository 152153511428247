@import '../../styles/modules/load.scss';

.ant-notification-topRight {
  margin-top: 7rem;
}

.ant-dropdown,
.ant-message,
.ant-popover-placement-bottom {
  z-index: 9999;
}

.public-status-control {
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $color-primary;
    border-color: $color-primary;
  }
}

.ant-switch-checked {
  background-color: $color-primary;
}

.ant-tabs {
  overflow: initial;

  .ant-tabs-nav {
    .ant-tabs-tab {
      font-size: 1.8rem;
      color: rgba(0, 0, 0, 0.85);

      &:hover {
        color: $text-blue;
      }
    }

    .ant-tabs-tab-active {
      color: $text-blue;
    }
  }

  .ant-tabs-nav-list {
    .ant-tabs-tab {
      text-align: center;
      // border-top-right-radius: 1rem;
      // border-top-left-radius: 1rem;
      transition: all 0.2s;
    }
  }

  .ant-tabs-bar {
    margin-bottom: 0;
  }

  .ant-tabs-content {
    background: #fff;
    box-shadow: $dark-shadow;
    // border-top-right-radius: 1rem;
    padding: 1.5rem;
  }
}

.ant-list-item-meta-title {
  font-size: 1.6rem;
}

.ant-list-item-meta-avatar {
  width: 10rem;
  text-align: center;
}

.ant-list-pagination {
  margin-bottom: 4rem;
  text-align: center;
}

.ant-table-body {
  padding: 1.5rem;
}

.ant-table-row {
  cursor: pointer;
}

.ant-table-pagination,
.ant-list-pagination {
  margin-top: 4rem;
  display: flex;
  justify-content: center;
}
