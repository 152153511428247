@import '../../../styles/modules/load.scss';

.audio-input {
  display: block;

  audio {
    outline: none;
  }

  input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
  input + label {
    color: $text-blue;
    display: inline-block;
    display: block;
    border: 1px solid transparent;
    background-color: rgba($base-blue, 0.1);
    padding: 1.5rem;
    border-radius: 3px;
    font-weight: 500;
    cursor: pointer;
  }
}
