.main-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 7rem;
  font-size: 1.4rem;
  background-color: #fff;
  border-bottom: $color-grey-light-2;
  box-shadow: $dark-shadow;

  .burger {
    display: flex;
    justify-content: flex-start;
    padding-left: 3rem;
    flex: 0 0 12%;
    cursor: pointer;
  }

  .search {
    flex: 0 0 40%;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    z-index: $high-priority;

    @include respond(phone) {
      flex: 0 0 32%;
    }

    &__wrapper {
      flex: 1;
    }

    &__input {
      font-family: inherit;
      font-size: inherit;
      color: inherit;
      background-color: $color-grey-light-2;
      border: none;
      padding: 0.7rem 2rem;
      border-radius: 10rem;
      width: 100%;
      transition: all 0.2s;
      margin-right: -3.25rem;

      &:focus {
        outline: none;
        background-color: $color-grey-light-3;
      }

      &::-webkit-input-placeholder {
        font-weight: 100;
        color: $color-grey-light-4;
      }
    }

    &__input:focus + &__button {
      background-color: $color-grey-light-3;
    }

    &__button {
      padding: 0;
      border: none;
      background-color: $color-grey-light-2;

      &:focus {
        outline: none;
      }

      &:active {
        transform: translateY(2px);
      }
    }

    &__icon {
      height: 2rem;
      width: 2rem;
      fill: $color-grey-dark-3;
    }
  }

  .see-all-results {
    @include remove-webkit();
    @include default-transition();
    position: absolute;
    top: 0.3rem;
    bottom: 0.3rem;
    right: 0.3rem;
    padding: 0.5rem 1.5rem;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: normal;
    background-color: transparent;
    border-radius: 2px;
    cursor: pointer;

    &:active {
      transform: scale(0.95);
    }
  }

  .top-search-results {
    position: absolute;
    top: 4rem;
    width: 100%;
    max-height: 35rem;
    background-color: white;
    border-radius: 2px;
    border: 1px solid $color-grey-light-2;
    -webkit-box-shadow: inset 0 7px 9px -7px $color-grey-light-2;
    box-shadow: inset 0 7px 9px -7px $color-grey-light-2;
    overflow: scroll;

    @include respond(tab-port) {
      width: 200%;
    }

    .no-results {
      display: block;
      text-align: center;
      p {
        font-size: 1.4rem;
        padding: 1.5rem;
        font-weight: 400;
        margin: 0;
        b {
          font-weight: 600;
        }
      }
    }
    ul {
      display: block;
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        padding: 1rem;
        border-bottom: 1px solid $color-grey-light-2;
        list-style: none;
        &:hover {
          background-color: $color-grey-light-1;
        }
        a {
          color: $text-dark-gray;
          font-size: 1.4rem;
          font-weight: 500;
          display: block;
          text-decoration: none;
          span.label {
            display: block;
            font-size: 1.2rem;
            color: $color-grey-dark-2;
            margin-top: 0.5rem;
          }
          span.artist {
            font-size: 1.2rem;
            color: $color-grey-dark-2;
            margin-left: 0.5rem;
          }
          span.onestop {
            position: relative;
            display: inline-block;
            color: #0071c5;
            font-size: 0.9rem;
            border: 1px solid $color-grey-light-1;
            background-color: $shadow-light-blue;
            padding: 0.2rem;
            border-radius: 2px;
            margin-top: 0.25rem;
          }
          .genres {
            display: block;
            span {
              font-size: 1.2rem;
              color: $color-grey-dark-2;
            }
          }
        }
      }
    }
  }

  .user-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;

    & > * {
      padding: 0 2rem;
      cursor: pointer;
      height: 100%;
      display: flex;
      align-items: center;

      @include respond(tab-port) {
        padding: 0 1rem;
      }
    }

    & > *:hover {
      background-color: $color-grey-light-2;
    }

    &__icon-box {
      position: relative;
      transition: all 0.2s;
    }

    &__icon {
      height: 2.25rem;
      width: 2.25rem;
      fill: $color-grey-dark-2;
    }

    &__notification {
      width: 1.75rem;
      height: 1.75rem;
      font-size: 1.2rem;
      border-radius: 50%;
      background-color: $color-primary;
      color: #fff;
      position: absolute;
      top: 1.5rem;
      right: 1.1rem;

      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__user-photo {
      width: 3.75rem;
      height: 3.75rem;
      border-radius: 50%;
      margin-right: 1rem;
    }
  }
}
