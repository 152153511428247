@import '../../styles/modules/load.scss';

.vw-sptf-importer {
  padding-bottom: 4rem;
  width: 100%;
  max-height: 100vh;

  .content-header {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    background-color: $color-grey-light-1;
    align-items: center;
  }

  .content-header-inner {
    @include respond(tab-port) {
      justify-content: center;
      text-align: center;
    }
  }

  .importer-icon {
    width: 2rem;
    height: 2rem;
    margin-right: 2rem;
    cursor: pointer;

    &:hover {
      fill: $color-primary-light;
    }
  }

  .importer-header {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    padding: 1rem;
    font-size: 1.8rem;
    text-transform: uppercase;
  }

  .import-btn {
    display: flex;
    align-items: center;
    height: 4.5rem;
    border-radius: $default-border-radius;
  }

  .importer {
    margin-top: 4rem;

    @include respond(tab-port) {
      padding: 1rem;
    }
  }

  .search-bar {
    .ant-input-group-addon {
      button {
        display: flex;
        align-items: center;
        margin: auto;
        padding-bottom: 1rem;
        height: 4rem;
        border-radius: $default-border-radius;
        color: white;
        background-color: $color-primary;
        border-color: $color-primary;
      }
    }
  }

  .is-selected,
  .is-focused {
    background-color: $color-primary-light;
    transition: all ease-in 0.1s;

    .title,
    .artist {
      color: #fff;
    }
  }

  .search-bar {
    margin-bottom: 2rem;
  }

  .search-type {
    margin-top: 2rem;
  }

  .search-spinner {
    display: block;
    margin: 8rem auto;
    margin-bottom: 1rem;
    width: 2.5rem;
    height: 2.5rem;
  }

  .list-header {
    padding-left: 4.5rem;
    border-bottom: 1px solid $border-default;
  }

  .react-list-select {
    overflow-y: scroll;
    max-height: 60vh;
    list-style: none;
    border: 1px solid $color-grey-light-1;
    outline: none;

    @include respond(tab-port) {
      padding: 0;
    }

    .item {
      padding: 5px;
      color: $text-dark;
      border-bottom: 1px solid $border-default;
      cursor: pointer;
      transition: all ease-in 1s;

      .title {
        font-weight: 500;
      }
    }
  }
}
