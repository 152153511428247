.u-center-text {
  text-align: center !important;
}

.u-mb-big {
  margin-bottom: 8rem !important;

  @include respond(tab-port) {
    margin-bottom: 5rem !important;
  }
}

.u-mb-medium {
  margin-bottom: 4rem !important;

  @include respond(tab-port) {
    margin-bottom: 3rem !important;
  }
}

.u-mb-small {
  margin-bottom: 1.5rem !important;
}

.u-mt-large {
  margin-top: 10rem !important;
}
.u-mt-big {
  margin-top: 8rem !important;
}

.u-capitalize {
  text-transform: capitalize;
}

.u-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.util-no-flow {
  position: fixed;
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.u-block {
  display: block;
}

.img-responsive {
  display: block;
  max-width: 100%;
  width: 100%;
  height: auto;
}

