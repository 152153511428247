$cell-padding: 1rem 0;

.main-column {
  width: 40%;
}

.actions-column {
  width: 20%;
}

table#large {
  background-color: transparent;
  vertical-align: top;
  border-radius: 3px;
  overflow: visible;
  width: 100%;

  tr {
    &:first-child {
      display: table-header-group;
      background-color: white;
      padding-top: 0 !important;

      th {
        padding: 1rem;
        padding-top: 0;
        font-size: 1.4rem;
        font-weight: 500;
        color: $color-primary;
        text-align: left;
        background-size: 1rem;
        background-position: center right;

        &:last-child {
          border-right: 0;
        }
      }
    }

    td {
      text-align: left;
      padding: 0.5rem 1rem;
      font-size: 1.4rem;
      font-weight: 400;
      color: $text-dark-gray;
      a {
        color: $text-dark-gray;
        text-decoration: none;
      }
    }
    &:nth-child(even) {
      background-color: $shadow-light-blue;
    }
    &:last-child {
      border-bottom: 0;
    }
    td {
      span {
        &#master,
        &#publishing,
        &#one-stop {
          background-color: $color-grey-dark-4;
          color: white;
          font-size: 1.2rem;
          position: relative;
          display: inline-block;
          border-radius: 3px;
          padding-top: 0.25rem;
          padding-left: 0.25rem;
          padding-bottom: 0.25rem;
          overflow: hidden;
        }
        &#master span.value,
        &#publishing span.value {
          background-color: $color-primary-light;
          padding: 0.25rem;
        }
        &#one-stop span.value {
          background-color: $text-green;
          padding: 0.25rem;
        }
      }
      &#options-row {
        position: relative;
        button#more {
          width: 1.5rem;
          height: 1.5rem;
          background-color: transparent;
          -webkit-appearance: none;
          outline: 0;
          text-align: center;
          display: block;
          margin: 0 auto;
          background-repeat: no-repeat;
          border: 0;
          background-size: cover;
          background-position: center;
          &:hover {
            cursor: pointer;
          }
          &.active {
            background-size: 0.8rem;
          }
        }
        #options {
          background-color: white;
          border: 1px solid #ececec;
          border-radius: 5px;
          position: absolute;
          z-index: 1;
          left: 0.5rem;
          right: 0.5rem;
          -moz-box-shadow: 0 0 1.5rem #ececec;
          -webkit-box-shadow: 0 0 1.5rem #ececec;
          box-shadow: 0 0 1.5rem #ececec;
          margin-top: 0.5rem;
          button {
            display: block;
            min-width: 100%;
            -webkit-appearance: none;
            outline: 0;
            border: 0;
            padding: 0.75rem 0;
            font-size: 1.2rem;
            border-bottom: 1px solid #ececec;
            &:hover {
              cursor: pointer;
              color: #fc4566;
            }
            &:last-child {
              border-bottom: 0;
            }
          }
        }
      }
      #ownership {
        display: block;
        width: max-content;
        background-color: white;
        border: 1px solid #ebf0f4;
        border-radius: 4px;
        text-align: center;
        margin-bottom: 0.25rem;
        &:last-child {
          margin-bottom: 0;
        }
        #ownership-inner {
          #title h1 {
            font-size: 0.75rem;
            padding: 0.25rem;
            font-weight: 500;
            margin: 0;
          }
          #percentage {
            h1 {
              font-size: 0.75rem;
              padding: 0.25rem;
              font-weight: 500;
              margin: 0;
            }
            display: inline-block;
            vertical-align: middle;
          }
          #title {
            display: inline-block;
            vertical-align: middle;
            border-right: 1px solid #ebf0f4;
            h1 {
              color: #3086ce;
            }
          }
          #progress #progress-percentage {
            background-color: #d4ffce;
            height: 100%;
          }
          #one-stop-checkmark {
            padding: 0.75rem;
            width: auto;
            height: auto;
            background-repeat: no-repeat;
            display: inline-block;
            background-size: 0.75rem;
            background-position: center;
            vertical-align: middle;
          }
        }
      }
    }
  }
}

table.content-table {
  width: 100%;
  background-color: #fff;
  border-radius: 2px;

  @include default-transition();
  &.fetching {
    opacity: 0.2;
  }

  tr {
    margin-bottom: 1rem;
    border-bottom: 1px solid $border-default;
    background-color: white;
    outline: none;

    &.table-header {
      padding: $cell-padding;

      th {
        padding: $cell-padding;
        padding-top: 0;
        text-align: left;
        font-size: 1.4rem;
        font-weight: 500;
        color: $text-dark;
        cursor: pointer;
        &.DESC,
        &.ASC {
          &::after {
            content: '';
            width: 1.4rem;
            height: 1.4rem;
            display: inline-block;
            background-size: 1.4rem;
            background-repeat: no-repeat;
            margin-left: 0.25rem;
          }
        }
        &.DESC {
          &::after {
            background-image: url('../../images/SVG/sort-desc.svg');
          }
        }
        &.ASC {
          &::after {
            background-image: url('../../images/SVG/sort-asc.svg');
          }
        }
        span {
          &.DESC,
          &.ASC {
            &::after {
              content: '';
              width: 1.4rem;
              height: 1.4rem;
              display: inline-block;
              background-size: 1.4rem;
              background-repeat: no-repeat;
            }
          }
          &.DESC {
            &::after {
              background-image: url('../../images/SVG/sort-desc.svg');
            }
          }
          &.ASC {
            &::after {
              background-image: url('../../images/SVG/sort-asc.svg');
            }
          }
        }
      }
    }
    &:last-child {
      border-bottom: 0;
      td {
        padding-bottom: 0;
      }
    }

    .artwork {
      margin-right: 1rem;
      width: 4rem;
      height: 4rem;
      background-color: whitesmoke;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      border-radius: 2px;
    }
    td {
      text-align: left;
      padding: $cell-padding;
      font-size: 1.4rem;
      color: $text-dark;
      a {
        color: $text-dark;
        text-decoration: none;
        &:hover {
          color: $text-blue;
        }
        &.title {
          color: $text-blue;
          font-weight: 500;
          margin-bottom: 0.5rem;
          .favorite {
            width: 0.7rem;
            height: 0.7rem;
            display: inline-block;
            vertical-align: middle;
            margin-top: -0.2rem;
            background-image: url('../../images/SVG/heart.svg');
            background-size: cover;
          }
        }
      }
      .ownership {
        display: block;
        span {
          font-size: 0.8rem;
          font-weight: 500;
          color: $text-dark-gray;
          &:first-child {
            margin-right: 0.5rem;
          }
        }
      }
      &.options {
        max-width: 3rem;
        text-align: center;
      }

      span.one-stop {
        background-image: url('../../images/SVG/onestop-notselected.svg');
        background-repeat: no-repeat;
        background-size: 0.8rem;
        background-position: center left;
        display: inline-block;
        padding-left: 1.2rem;
        font-size: 0.8rem;
        font-weight: 500;
        color: $text-dark-gray;
      }
    }
  }

  .selected-row {
    background: #c2dbff;
  }
}
/* Ownership table data */

#card-table {
  display: inline-block;
  padding: 0;
  vertical-align: top;
  border: 1px solid $color-grey-light-2;
  border-radius: 2px;
  background-color: white;
  overflow: hidden;

  &:first-child {
    width: 65%;
    margin-right: 2.5%;
  }
  &:last-child {
    width: 32.5%;
  }
  #card-table-header {
    display: block;
    padding: 1rem;
    border-bottom: 1px solid $color-grey-light-2;
    #col {
      display: inline-block;
      width: 50%;
      h2 {
        margin: 0;
        font-size: 1rem;
        font-weight: 500;
        color: #0071c5;
      }
      &:last-child {
        text-align: right;
      }
    }
  }
  #card-table-body #card-table-item {
    display: block;
    border-bottom: 1px solid $shadow-light-blue;
    padding: 1rem;
    #col {
      display: inline-block;
      vertical-align: middle;
      &:first-child {
        width: 60%;
      }
      &:last-child {
        width: 40%;
        text-align: right;
      }
      h4 {
        display: block;
        width: 100%;
        font-size: 0.8rem;
        font-weight: 500;
        text-decoration: none;
        color: $text-dark-gray;
        margin: 0;
      }
    }
  }
}
