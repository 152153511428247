.entity-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  flex-wrap: wrap;
  transform: translate(-50%, -50%);

  .ant-spin {
    width: 100%;
  }
}
