@import '../../../styles/modules/load.scss';

.forms-input {
  input {
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    padding: 1rem;
    resize: none;
    color: $text-dark;
    @include remove-webkit();

    &:focus {
      border-color: $border-dark-blue;
      outline-width: 0px;
    }
  }
}
