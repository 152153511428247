@import '../../../styles/modules/load.scss';

.forms-input {
  textarea {
    width: 100%;
    padding: 1rem;
    padding-top: 3rem;
    padding-bottom: 0.75rem;
    display: inline-block;
    vertical-align: middle;
    resize: none;
    color: $text-dark;
    @include remove-webkit();

    &:focus {
      border-color: $border-dark-blue;
      box-shadow: inset 0 1px 2px rgba($text-dark-gray, 0.075),
        0 0 0 3px rgba($border-dark-blue, 0.2);
    }
  }
}
