@import '../../../styles/modules/load';

#select {
  display: block;
  position: relative;
  #select-input {
    display: block;
    background-color: white;
    border: 1px solid $color-grey-light-3;
    padding: 1rem;
    border-radius: 2px;
    overflow: hidden;
    position: relative;
    &.focused {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    input {
      width: -webkit-fill-available;
      font-size: 1.4rem;
      border: 0;
      font-weight: 500;
      outline: 0;
      display: inline-block;
    }
    button {
      display: inline-block;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      border: 0;
      outline: 0;
      background-color: white;
      color: $text-red;
      font-size: 1.8rem;
      padding: 0 1rem;
      border-left: 1px solid $color-grey-light-2;
      background-color: white;
      &:hover {
        cursor: pointer;
        background-color: $base-light-gray;
      }
    }
  }
  #select-options {
    background-color: white;
    border: 1px solid $color-grey-light-1;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    position: absolute;
    width: 100%;
    border-top: 0px;
    max-height: 15rem;
    overflow-y: scroll;
    -webkit-box-shadow: $dark-shadow;
    box-shadow: $dark-shadow;
    z-index: 2;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        padding: 1rem 1rem;
        font-size: 1.4rem;
        border-bottom: 1px solid $color-grey-light-3;
        font-weight: 500;
        color: $text-dark-gray;

        &:hover {
          cursor: pointer;
          background-color: $color-grey-light-2;
        }
        &:last-child {
          border-bottom: 0px;
        }
        &#nothing-found {
          color: $text-red;
          &:hover {
            background-color: white;
            cursor: initial;
          }
        }
      }
    }
  }
}

/* Select options */
