.content-module {
  .content-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
    text-transform: uppercase;
    background-color: $color-grey;

    .anticon-more {
      font-size: 2.6rem;
    }

    h3 {
      font-size: 1.8rem;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .plus-action {
    font-size: 2.4rem;
    cursor: pointer;
  }

  .content-container {
    padding: 1rem;
    background-color: white;
    border-radius: 2px;
  }

  .search-bar {
    margin-top: 2rem;
    margin-bottom: 1rem;
    // border-bottom: 1px solid $border-default;
    padding-right: 2px;
    padding-left: 2px;

    .anticon-search {
      padding-right: 0.5rem;
    }

    .ant-input-suffix {
      display: none;
    }

    .ant-btn {
      display: flex;
      align-items: center;
      font-size: 2rem;
      background-color: $color-primary;
      border-color: $color-primary-light;
      border-radius: 1rem;
    }
  }

  .content-table {
    .first-cell {
      padding-left: 1.2rem;
    }

    .last-cell {
      padding-right: 1.2rem;
    }
  }

  table.content-table tr:last-child td {
    padding-bottom: 0.75rem;
  }

  .react-list-select {
    padding-left: 0;
    list-style: none;

    .item {
      padding-top: 1rem;
      padding-bottom: 1rem;
      font-size: 1.4rem;
      color: $text-dark-gray;
      border-bottom: 1px solid $color-grey;

      &:hover {
        color: #fff;
      }
    }
  }

  .is-selected {
    background-color: $color-primary-light;

    .item {
      color: #fff !important;
    }
  }

  .item {
    cursor: pointer;

    &:hover {
      background-color: $color-primary-light;

      .toggle {
        color: #fff;
      }

      td {
        color: #fff;
      }
    }
  }
}

.vw-module {
  width: 100%;

  .ant-tabs .ant-tabs-content {
    padding: 0;
    box-shadow: none;
  }

  .view-module-title {
    .anticon-close {
      font-size: 2.4rem;
      padding-right: 1rem;
    }
  }

  .module-image {
    display: flex;
    margin-top: 1rem;
    margin-bottom: 2rem;

    .module-titles {
      padding-left: 1.5rem;

      .ant-typography {
        margin-bottom: 0;
      }
    }
  }

  .module-container {
    padding: 1.2rem;
  }

  .tracks-header {
    padding: 1rem;
    font-size: 1.4rem;
    font-weight: 500;
    color: $text-dark-gray;
    border-bottom: 1px solid $border-default;
  }

  .ant-descriptions-item-content {
    width: 60%;
  }

  .ant-select-single {
    width: 100% !important;
  }

  .instructions {
    margin-top: 4rem;
    padding: 2rem;
    background-color: $color-grey;
    border-radius: 0.5rem;
    text-align: center;
  }
}

.edit-module {
  .ant-picker {
    width: 100%;
  }

  .ant-btn {
    display: inline-block;
    margin: 1rem;
  }

  .module-title {
    justify-content: space-between;
  }

  .image-field {
    .ant-form-item-label {
      text-align: center;

      label {
        font-size: 1.8rem;
      }
    }

    .ant-upload-select-picture-card {
      float: initial;
      width: 20rem;
      height: 20rem;
      margin: auto;
    }
  }
}

.content-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 100;
  width: 8rem;
  height: 8rem;
}

.content-overlay {
  z-index: 99;
}
